import React, { useEffect, useReducer, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';

import '../generic-page.scss';
import './tier-list.scss';
import lodash from 'lodash';
import { faAnglesDown, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ILimbusCharacter } from '../../modules/common/model/graphql-types';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { LimbusCharacter } from '../../modules/lc/common/components/lc-character';
import { Accordion, Button, Card } from 'react-bootstrap';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { LCSpecialitiesArray } from '../../modules/lc/model/lc-spec';
import Switch from 'react-switch';

interface ILimbusCharacterNodes {
  nodes: ILimbusCharacter[];
}

interface ILimbusCharacterEntry {
  allCharacters: ILimbusCharacterNodes;
}

interface IProps {
  data: ILimbusCharacterEntry;
}

const LimbusTierListPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);
  const [currentTag, setCurrentTag] = useState(false);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search identities...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '0', value: '0' },
          { label: '00', value: '00' },
          { label: '000', value: '000' }
        ]
      },
      {
        key: 'affinity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Envy',
            tooltip: 'Envy',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_envy.png"
                width={24}
                alt="Envy"
              />
            )
          },
          {
            value: 'Gloom',
            tooltip: 'Gloom',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_gloom.png"
                width={24}
                alt="Gloom"
              />
            )
          },
          {
            value: 'Gluttony',
            tooltip: 'Gluttony',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_gluttony.png"
                width={24}
                alt="Gluttony"
              />
            )
          },
          {
            value: 'Lust',
            tooltip: 'Lust',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_lust.png"
                width={24}
                alt="Lust"
              />
            )
          },
          {
            value: 'Pride',
            tooltip: 'Pride',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_pride.png"
                width={24}
                alt="Pride"
              />
            )
          },
          {
            value: 'Sloth',
            tooltip: 'Sloth',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_sloth.png"
                width={24}
                alt="Sloth"
              />
            )
          },
          {
            value: 'Wrath',
            tooltip: 'Wrath',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/affinity_wrath.png"
                width={24}
                alt="Wrath"
              />
            )
          }
        ]
      },
      {
        key: 'attack_type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Blunt',
            tooltip: 'Blunt',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/attackt_blunt.png"
                width={24}
                alt="Blunt"
              />
            )
          },
          {
            value: 'Pierce',
            tooltip: 'Pierce',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/attackt_pierce.png"
                width={24}
                alt="Pierce"
              />
            )
          },
          {
            value: 'Slash',
            tooltip: 'Slash',
            image: (
              <StaticImage
                src="../../images/limbuscompany/icons/attackt_slash.png"
                width={24}
                alt="Slash"
              />
            )
          }
        ]
      },
      {
        key: 'sinner',
        type: 'dropdown',
        values: [
          { label: 'Any Sinner', value: 'all' },
          {
            label: 'Don Quixote',
            value: 'Don Quixote'
          },
          {
            label: 'Faust',
            value: 'Faust'
          },
          {
            label: 'Gregor',
            value: 'Gregor'
          },
          {
            label: 'Heathcliff',
            value: 'Heathcliff'
          },
          {
            label: 'Hong Lu',
            value: 'Hong Lu'
          },
          {
            label: 'Ishmael',
            value: 'Ishmael'
          },
          {
            label: 'Meursault',
            value: 'Meursault'
          },
          {
            label: 'Outis',
            value: 'Outis'
          },
          {
            label: 'Rodion',
            value: 'Rodion'
          },
          {
            label: 'Ryoshu',
            value: 'Ryoshu'
          },
          {
            label: 'Sinclair',
            value: 'Sinclair'
          },
          {
            label: 'Yi Sang',
            value: 'Yi Sang'
          }
        ]
      },
      { key: 'speciality', type: 'dropdown', values: LCSpecialitiesArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.attack_type && activeFilters.attack_type !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.attackType &&
            emp.attackType.length > 0 &&
            emp.attackType.indexOf(activeFilters.attack_type) > -1
        );
      }
      if (activeFilters.affinity && activeFilters.affinity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.affinity &&
            emp.affinity.length > 0 &&
            emp.affinity.indexOf(activeFilters.affinity) > -1
        );
      }
      if (activeFilters.sinner && activeFilters.sinner !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.baseCharacter.toLowerCase() ===
            activeFilters.sinner.toLowerCase()
        );
      }
      if (activeFilters.speciality && activeFilters.speciality !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.speciality &&
            emp.speciality.length > 0 &&
            emp.speciality.indexOf(activeFilters.speciality) > -1
        );
      }
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [{ value: 'pve', label: 'PVE' }];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  const tagChangeHandler = (e) => {
    setCurrentTag(e);
    forceUpdate();
  };

  return (
    <DashboardLayout className={'generic-page tier-list-limbus'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/limbuscompany/categories/category_4.jpg"
            alt="Tier list"
          />
        </div>
        <div className="page-details">
          <h1>Limbus Company Tier List</h1>
          <h2>A tier list for Limbus Company.</h2>
          <p>
            Last updated: <strong>10/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <SectionHeader title="Tier list" />
      <Accordion defaultActiveKey="0" className="tier-list-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>About the Tier List</Accordion.Header>
          <Accordion.Body>
            <p>
              The Identities are actually decently balanced and you can make a
              lot of them work. This tier list tries to rate these IDs on their
              average performance in Railway content.
            </p>
            <ul>
              <li>
                Ratings take into account potential team synergies, rated with
                only their base ego, and in their ideal team composition.
              </li>
              <li>
                EGO synergies are mentioned in reviews and do not affect
                placement on the tier list.
              </li>
              <li>
                Ratings are based on Tier 4 Identities. If any ID requires Uptie
                4 to function, please check their tags on the tier list or check
                out our "Uptie 4 Priority" Guide.
              </li>
              <li>
                Identities within a tier are placed in alphabetical order.
              </li>
            </ul>
            <p>
              <strong>NOTE:</strong> We also added tags below each character to
              facilitate understanding their niche and help with team building.
              If you want to know more about each character, please check their
              page for full information and review.
            </p>
            <p>Catch us on our Discord if you want to provide feedback!</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Categories and Tags</Accordion.Header>
          <Accordion.Body>
            <h6>Categories</h6>
            <ul>
              <li>
                <strong className="dps">Damage Dealer:</strong> Group that ranks
                IDs that focus purely on doing damage. Even if they have any
                semblance of any status effect, supportive buff or debuffs,
                those are usually secondary additions in the kit and/or their
                damage scales with the amount of a certain status effect, making
                them synergetic to that team.
              </li>
              <li>
                <strong className="support">Status Specialist:</strong> Group
                focused on IDs whose main focus is status effect support, making
                them stronger, easier stacking or utilizing them more
                effectively.
              </li>
              <li>
                <strong className="sustain">Support:</strong> Group focused on
                IDs whose main purpose is to support the team with various buffs
                / effects or debuff enemies rather than damage or status
                effects.
              </li>
              <li>
                <strong className="tank">Tank:</strong> Group focused on IDs
                whose main purpose is to redirect damage for the team and tank
                hits.
              </li>
            </ul>
            <p>
              Some IDs could fall naturally into multiple categories, like
              Damage Dealer or Tank. While rating those IDs we will consider
              what is the main focus of such IDs.
            </p>
            <h6>Specialty Tags</h6>
            <p>
              There's mainly tags for what ID specializes in what, and none of
              them is a con. If Uptie 4 is a requirement for their kit to even
              function, it would be listed as a downside for that ID. Statuses
              that are already explained in the game (i.e Tremor, Charge,
              Sinking, Rupture, etc) will not be listed here.
            </p>
            <ul>
              <li>
                <strong>AoE Skills</strong> - The ID has access to damage
                multiple enemies at once in their kit.
              </li>
              <li>
                <strong>Coin Reuse</strong> - The ID has skills which reuses
                it's coins.
              </li>
              <li>
                <strong>Nuker</strong> - The ID specializes in dealing a large
                amount of damage in a single turn.
              </li>
              <li>
                <strong>Debuffer</strong> - The ID specializes in giving enemies
                negative status effects.
              </li>
              <li>
                <strong>Buffer</strong> - The ID specializes in giving allies
                and/or themself positive status effects.
              </li>
              <li>
                <strong>Discard</strong> - The ID's core gimmick is discarding
                skills.
              </li>
              <li>
                <strong>Every Status</strong> - The ID has the ability to
                potentially inflict every status in the game.
              </li>
              <li>
                <strong>Negative SP</strong> - The ID's core gimmick is that
                they use negative coin skills.
              </li>
              <li>
                <strong>Counter</strong> - The ID specializes in their counter
                skill.
              </li>
              <li>
                <strong>HP Gimmick</strong> - The ID's core gimmick lies in
                being above/below a certain HP threshold.
              </li>
              <li>
                <strong>A-Reson/Reson</strong> - The ID's core gimmick lies in
                getting Sin Resonance or Absolute Sin Resonance.
              </li>
              <li>
                <strong>Passive Provider</strong> - The ID has a pretty useful
                Support Passive.
              </li>
              <li>
                <strong>Req. U4</strong> - The ID needs Uptie 4 to work on an
                efficient level.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Criteria</Accordion.Header>
          <Accordion.Body>
            <h6>Criteria</h6>
            <ul>
              <li>All conditionals when ranking these are fulfilled</li>
              <li>Using optimal team composition</li>
              <li>
                Railway buffs and support passives are not taken into account
              </li>
              <li>
                Mirror Dungeons and unique Story buffs aren't taken into account
              </li>
            </ul>
            <h6>Roles</h6>
            <p>
              We compare characters within their role - so damage dealers are
              compared to other damage dealers and the ratings are adjusted
              based on their performance within the role.
            </p>
            <ul>
              <li>
                <strong className="dps">Damage Dealer:</strong> Rating is
                heavily influenced based on the ID's damage and utility to both
                deal damage and amplify the damage of others, including their
                own.
              </li>
              <li>
                <strong className="support">Status Specialist:</strong> Rating
                is heavily influenced based on the ID's ability to stack the
                count and potency of every status or brings something unique to
                that status to make it overall effective and stronger.
              </li>
              <li>
                <strong className="sustain">Support:</strong> Rating is heavily
                influenced based on the ID's ability to give buffs to allies,
                debuffs to enemies, and overall utility to help defeat enemies
                quicker.
              </li>
              <li>
                <strong className="tank">Tank:</strong> Rating is heavily
                influenced based on the ID's ability to take damage and their
                benefits to taking damage
              </li>
            </ul>
            <h6>Other criteria affecting ratings</h6>
            <p>
              Clashing and ability/capacity to deal damage is usually the
              biggest defining factor that differentiates many IDs compared to
              others. We consider:
            </p>
            <ul>
              <li>
                <strong>Flexibility</strong> - How the ID's kit functions on its
                own and how difficult it is to play.
              </li>
              <li>
                <strong>Clashing</strong> - How good the ID is at clashing. We
                consider 15-17 to be the exact average for clashes. Everything
                less than 14 is below average, while everything above 18 is
                above average.
              </li>
              <li>
                <strong>Utility</strong> - There's a great number of IDs that
                possess abilities that lie beyond their defined role, for
                example Pequod Heathcliff scales in damage the lower HP he has,
                and gains buffs whenever allies or himself gets hit to deal even
                more damage. The more an ID helps the team outside of their
                role, the bigger positive impact on their rating it has.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Meta Lines and Ratings</Accordion.Header>
          <Accordion.Body>
            <h6>Meta Lines</h6>
            <p>
              Meta Lines categorize each section of the tier list into a power
              bracket. Here are the four brackets:
            </p>
            <ul>
              <li>
                <strong>Best in Role Characters (SS & S+)</strong> - IDs in this
                bracket are the best of the best. They can dish out outrageous
                damage, enables a ton of synergy with other IDs, and provide
                massive buffs or debuffs with their kits. They are, most of the
                time, essential to achieving the lowest turn counts for Railway
                content, while also making all other content in this game a
                complete pushover.
              </li>
              <li>
                <strong>Meta Characters (S & A)</strong> - Characters in this
                bracket perform extremely well in content, but compared to the
                tier above them, they don't perform as well or more work is
                required for them to operate. They'll still be able to
                consistently clear all forms of content though.
              </li>
              <li>
                <strong>Niche Characters (B)</strong> - Characters in this
                bracket have the really short end of the stick, either extremely
                reliant on the team they're put in, requires a bunch of effort
                to get working, or just overall does not perform as good as the
                characters in the higher tiers.
              </li>
              <li>
                <strong>Bad Characters (C & D)</strong> - Characters in this
                bracket are just not good. Even in their best conditions they
                have absolutely nothing that makes them even remotely useful to
                the team. You'd actively be crippling yourself by using them.
              </li>
            </ul>
            <h6>Ratings</h6>
            <ul className="no-margin">
              <li>
                <strong>SS</strong> - Absolute best in their respective
                category. Offers more than Identities in tiers below or an
                unique / core part of the kit makes them stand out.
              </li>
              <li>
                <strong>S+</strong> - Extremely exceptional IDs with really
                solid kits, although held back by their versatility or lack of
                utility that SS IDs have.
              </li>
              <li>
                <strong>S</strong> - Extremely viable IDs that perform well and
                still have a place in the meta, but requires a bit more work to
                truly shine.
              </li>
              <li>
                <strong>A</strong> - Still good and viable, although has a good
                number of issues or their drawbacks hold them down.
              </li>
              <li>
                <strong>B</strong> - Only viable in certain scenarios. These IDs
                has certain niches that are not good and requires more
                investment to make them work.
              </li>
              <li>
                <strong>C</strong> - Average characters that have big flaws and
                struggle very hard to even play their own role.
              </li>
              <li>
                <strong>D</strong> - Detrimental, passive providers only or
                completely overshadowed by other IDs. Avoid at all costs.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Changelog</Accordion.Header>
          <Accordion.Body>
            <h6>17.10.2024</h6>
            <p>Added recently released Identities.</p>
            <h6>09.10.2024</h6>
            <p>Added recently released Identities.</p>
            <h6>22.09.2024</h6>
            <p>Added recently released Identities.</p>
            <h6>10.09.2024</h6>
            <p>Added recently released Identities.</p>
            <p>Other changes:</p>
            <ul>
              <li>
                <strong>[W Corp. L3 Cleanup Captain] Outis</strong> - A Tier
                Support &gt; S Tier Status Specialist - In the category of
                Status Specialist, W Outis' spread of Charge Barrier is
                extremely helpful for Charge IDs who struggle with maintaining
                their count.
              </li>
              <li>
                <strong>[Seven Assoc. South Section 4] Faust</strong> - S Tier
                Damage Dealer &gt; A Tier Damage Dealer - While 7 Faust works
                pretty well on a Rupture team, without one she struggles to pull
                her own weight, especially with her average clashes and mediocre
                passive.
              </li>
              <li>
                <strong>[Lobotomy E.G.O::Regret] Faust</strong> - S Tier Damage
                Dealer &gt; S+ Tier Damage Dealer - Outside of just dealing
                damage, Regret Faust's passive is just really incredible for
                Tremor teams, alongside the Plus Coin Drop from her S2 just
                generally being nice to have, especially for the units on a
                Tremor Team who has lower numbers.
              </li>
              <li>
                <strong>[The One Who Shall Grip] Sinclair</strong> - SS Tier
                Damage Dealer &gt; S+ Tier Damage Dealer - N Sinclair sports
                incredible consistency in fights, however in the new style of
                battles, he doesn't offer much besides just being consistent.
              </li>
              <li>
                <strong>[Cinq South Section 4 Director] Sinclair</strong> - SS
                Tier Damage Dealer &gt; S+ Tier Damage Dealer - Mostly the same
                case as N Sinclair, however his S3 fragility is tied to whether
                or not it's the only skill targeting the enemy slot it's on, and
                in the case of staggered abnormalities or mob fights, you can
                practically kiss that fragility goodbye.
              </li>
              <li>
                <strong>[Blade Lineage Salsu] Yi Sang</strong> - A Tier Damage
                Dealer &gt; S Tier Damage Dealer - Due to the longer form of
                battles with Chain Battles, BL Yi Sang can properly scale with
                his poise generation.
              </li>
              <li>
                <strong> [Lobotomy E.G.O::Red Sheet] Sinclair</strong> - S Tier
                Status Specialist &gt; B Tier Status Specialist - WAY, WAY too
                rng dependant to work properly. Assuming we do get single boss
                battles in the future, he still has his niche of best Rupture
                application in the game, but only if you get the stars to align.
                In any mob fight, or fights with multiple waves, this ID will
                almost always be straight up useless or just not worth the
                effort of setting up.
              </li>
              <li>
                <strong>[Rosespanner Workshop Fixer] Meursault</strong> - B Tier
                Status Specialist &gt; C Tier Status Specialist - Severely
                powercrept by every other Tremor ID in the game. Combined with
                the awful clashes and Tremor application, this ID does not have
                a spot in any future content at all.
              </li>
              <li>
                <strong>[N Corp. Mittelhammer] Rodion</strong> - B Tier Status
                Specialist &gt; C Tier Status Specialist - Extremely bad
                clashes, damage and bleed application.
              </li>
              <li>
                <strong>[Dieci Assoc. South Section 4] Yi Sang</strong> - A Tier
                Tank &gt; B Tier Tank - Clashes are straight up just awful
                without conditionals, and even with them they're pretty average.
                As a tank ID I can see it being very competent but you're just
                never going to win clashes with this guy and sinking infliction
                is pretty whack at best.
              </li>
            </ul>
            <h6>28.08.2024</h6>
            <p>Added [Wild Hunt] Heathcliff</p>
            <h6>15.08.2024</h6>
            <p>Added new Identities.</p>
            <h6>05.08.2024</h6>
            <p>Fully reworked the tier list.</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="banner-limbus-promo-tl">
        <h4>Reroll and play Limbus Company on PC!</h4>
        <OutboundLink href="https://bstk.me/mGKpobpBu" target="_blank">
          <Button variant="primary">Play now for free</Button>
        </OutboundLink>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <div className="employees-filter-bar limbus limbus-id">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="eidolon-switcher">
        <h5>Tier list settings</h5>
        <div className="custom-switch">
          <Switch
            checked={currentTag}
            onChange={tagChangeHandler}
            onColor="#009EEC"
            offColor="#484950"
            className="switch"
          />
          Show tags instead of names
        </div>
      </div>
      <div className="custom-tier-list-limbus">
        <div className="custom-tier-header">
          <div className="tier-rating">
            <span>&nbsp;</span>
          </div>
          <div className="custom-tier-container">
            <div className="custom-header dps">Damage dealer</div>
            <div className="custom-header support">Status Specialist</div>
            <div className="custom-header survival">Support</div>
            <div className="custom-header tank">Tank</div>
          </div>
        </div>
        <div className={`meta-line visible`}>
          <h5 className="meta-header bis">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Best in Role{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-ss first ">
          <div className="tier-rating ss">
            <span>SS</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '10')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '10')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '10')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '10')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-s-plus">
          <div className="tier-rating s-plus">
            <span>S+</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9.5')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9.5')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9.5')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9.5')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line visible`}>
          <h5 className="meta-header meta">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Meta{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-s">
          <div className="tier-rating s">
            <span>S</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '9')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-a">
          <div className="tier-rating a">
            <span>A</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '8')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '8')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '8')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '8')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line visible`}>
          <h5 className="meta-header niche">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-b">
          <div className="tier-rating b">
            <span>B</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '7')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '7')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '7')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '7')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line visible`}>
          <h5 className="meta-header bad">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Bad{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-c">
          <div className="tier-rating c">
            <span>C</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '6')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '6')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '6')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '6')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-d">
          <div className="tier-rating d">
            <span>D</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage Dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '5')
                .filter((emp) => emp.tierCategory === 'dps')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Status Specialist</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '5')
                .filter((emp) => emp.tierCategory === 'status')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Support</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '5')
                .filter((emp) => emp.tierCategory === 'support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile tank">Tank</div>
            <div className="employees-container tank">
              {visibleCharacters
                .filter((emp) => emp.ratings[sortOption] === '5')
                .filter((emp) => emp.tierCategory === 'tank')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <LimbusCharacter
                          slug={emp.slug}
                          mode="icon"
                          showTags
                          tierList
                          enablePopover
                        />
                        {currentTag === false ? (
                          <>
                            <p className="tags name">{emp.name}</p>
                          </>
                        ) : (
                          <>
                            <p className="tags">[{emp.tierListTags}]</p>
                          </>
                        )}
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297007"></div>
    </DashboardLayout>
  );
};

export default LimbusTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier list | Limbus Company | Prydwen Institute"
    description="Tier list for Limbus Company gacha game."
    game="limbus"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulLimbusCharacters(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        name
        unitId
        slug
        rarity
        baseCharacter
        attackType
        speciality
        affinity
        isNew
        isReviewPending
        tierListTags
        tierCategory
        ratings {
          pve
        }
      }
    }
  }
`;
